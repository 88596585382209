/*  switching these values to their hex color channels so that they may work with the opacity sytax in tailwind 
  https://tailwindcss.com/docs/customizing-colors#using-css-variables
*/

:root {
  --alert-error-default: 209 50 22; /* #D13216 */
  --alert-error-default-on-dark: 234 77 49; /* #EA4D31 */
  --alert-gold-default: 149 103 9; /* #956709 */
  --alert-info-default: 15 86 166; /* #0F56A6 */
  --alert-info-default-on-dark: 51 139 237; /* #338BED */
  --alert-promote-default: 69 52 224; /* #4534E0 */
  --alert-promote-default-on-dark: 132 121 235; /* #8479EB */
  --alert-success-default: 19 144 146; /* #139092 */
  --alert-success-default-on-dark: 23 175 177; /* #17AFB1 */
  --alert-ui-default: 209 50 22; /* #D13216 */
  --alert-ui-disabled: 255 239 239; /* #FFEFEF */
  --alert-ui-hover: 234 77 49; /* #ea4d31 */
  --alert-ui-pressed: 167 46 24; /* #A72E18 */
  --alert-warning-default: 233 98 1; /* #E96201 */
  --alert-warning-default-on-dark: 254 109 5; /* #FE6D05 */
  --alert-warning-pressed: 254 109 5; /* #FE6D05 */
  --background-container-brand: 255 229 79; /* #FFE54F */
  --background-container-dark: 32 31 27; /* #201F1B */
  --background-container-default: 255 255 255; /* #FFFFFF */
  --background-container-disabled: 250 250 248; /* #FAFAF8 */
  --background-container-disabled-on-dark: 92 92 92; /* #5C5C5C */
  --background-container-hover: 240 240 237; /* #F0F0ED */
  --background-container-hover-on-dark: 67 67 65; /* #434341 */
  --background-container-input-error-on-dark: 76 25 17; /* #4C1911 */
  --background-container-input-hover-on-dark: 11 56 109; /* #0B386D */
  --background-container-pressed: 235 235 233; /* #EBEBE9 */
  --background-container-pressed-on-dark: 53 52 50; /* #353432 */
  --background-container-promoitemblue: 210 230 252; /* #D2E6FC */
  --background-container-promoitemgreen: 206 236 221; /* #CEECDD */
  --background-container-promoitemyellow: 255 242 158; /* #FFF29E */
  --background-container-promoitemnavy: 11 56 109; /* #0B386D */
  --background-container-tint: 247 247 244; /* #F7F7F4 */
  --background-container-tint-dark: 227 227 225; /* #E3E3E1 */
  --background-container-tint-light: 247 247 244; /* #F7F7F4 */
  --background-error: 255 231 231; /* #FFE7E7 */
  --background-feature: 174 207 255; /* #AECFFF */
  --background-global-dark: 0 0 0; /* #000000 */
  --background-gold: 255 246 191; /* #FFF6BF */
  --background-inform: 230 242 252; /* #E6F2FC */
  --background-page-blue: 240 247 253; /* #F0F7FD */
  --background-page-default: 255 255 255; /* #FFFFFF */
  --background-page-green: 240 250 244; /* #F0FAF4 */
  --background-page-tint: 247 247 244; /* #F7F7F4 */
  --background-page-yellow: 255 251 229; /* #FFFBE5 */
  --background-promote: 236 234 255; /* #ECEAFF */
  --background-promote-contrast: 69 52 224; /* #4534E0 */
  --background-success: 230 246 236; /* #E6F6EC */
  --background-success-contrast: 15 119 120; /* #0F7778 */
  --background-warning: 255 234 218; /* #FFEADA */
  --black: 0 0 0; /* #000000 not in the doc */
  --elevation-0: 0 0 #0000;
  --elevation-raised: 0px 1px 2px rgba(34 34 34 / 0.08), 0px 4px 16px rgba(34 34 34 / 0.1);
  --elevation-floating: 0px 2px 2px rgba(34 34 34 / 0.1), 0px 6px 16px rgba(34 34 34 / 0.18);
  --elevation-overflow-top: 0px 2px 4px 0px rgba(34, 34, 34, 0.08), 0px 0px 2px 0px rgba(34, 34, 34, 0.15);
  --elevation-overflow-bottom: 0px -2px 4px 0px rgba(34, 34, 34, 0.08), 0px 0px 2px 0px rgba(34, 34, 34, 0.15);
  --primary-brand-default: 253 219 0; /* #FDDB00 */
  --primary-brand-disabled: 255 246 191; /* #FFF6BF */
  --primary-brand-gold: 255 205 44; /* #FFCD2C */
  --primary-brand-hover: 254 239 144; /* #FEEF90 */
  --primary-brand-pressed: 252 203 0; /* #FCCB00 */
  --primary-ui-default: 15 86 166; /* #0F56A6 */
  --primary-ui-default-on-dark: 255 255 255; /* #FFFFFF */
  --primary-ui-disabled: 209 222 237; /* #D1DEED */
  --primary-ui-focus: 174 207 255; /* #AECFFF */
  --primary-ui-focus-input: 29 116 222; /* #1D74DE */
  --primary-ui-hover: 29 116 222; /* #1D74DE */
  --primary-ui-pressed: 13 69 134; /* #0D4586 */
  --text-disabled: 203 203 203; /* #CBCBCB */
  --text-disabled-on-dark: 118 118 118; /* #767676 */
  --text-error: 167 46 24; /* #A72E18 */
  --text-error-on-dark: 247 171 159; /* #F7AB9F */
  --text-feature-tag: 32 31 27; /* #201F1B */
  --text-gold: 149 103 9; /* #956709 */
  --text-light: 255 255 255; /* #FFFFFF */
  --text-link-default: 15 86 166; /* #0F56A6 */
  --text-link-hover: 29 116 222; /* #1D74DE */
  --text-link-light-default: 255 255 255; /* #FFFFFF */
  --text-link-light-hover: 141 141 141; /* #8D8D8D */
  --text-link-light-pressed: 183 183 183; /* #B7B7B7 */
  --text-link-pressed: 13 69 134; /* #0D4586 */
  --text-placeholder: 87 87 87; /* #575757 */
  --text-primary: 32 31 27; /* #201F1B */
  --text-primary-on-dark: 255 255 255; /* #FFFFFF */
  --text-promote: 42 27 175; /* #2A1BAF */
  --text-promote-on-dark: 163 155 240; /* #A39BF0 */
  --text-secondary: 87 87 87; /* #575757 */
  --text-secondary-on-dark: 171 171 171; /* #ABABAB */
  --text-success: 15 114 115; /* #0F7273 */
  --text-success-on-dark: 26 193 195; /* #1AC1C3 */
  --text-success-tag: 15 119 120; /* #0F7778 */
  --text-tertiary: 106 106 106; /* #6A6A6A */
  --text-warning: 178 75 1; /* #B24B01 */
  --text-warning-on-dark: 255 141 60; /* #FF8D3C */
  --text-warning-tag: 178 75 1; /* #B24B01 */
  --ui-element-divider: 106 106 106; /* #6A6A6A */
  --ui-element-divider-decorative: 183 183 183; /* #B7B7B7 */
  --ui-element-outline-contrast: 32 31 27; /* #201F1B */
  --ui-element-outline-decorative: 183 183 183; /* #B7B7B7 */
  --ui-element-outline-decorative-disabled: 219 222 235; /* #DBDEEB */
  --ui-element-outline-decorative-on-dark: 87 87 87; /* #575757 */
  --ui-element-outline-default: 106 106 106; /* #6A6A6A */
  --ui-element-outline-default-on-dark: 141 141 141; /* #8D8D8D */
  --ui-element-outline-disabled: 217 217 215; /* #D9D9D7 */
  --ui-element-outline-disabled-on-dark: 87 87 87; /* #575757 */
  --ui-element-outline-hover: 106 106 106; /* #6A6A6A */
  --white: 255 255 255; /* #ffffff not in the doc */
  --font-family-editorial-header: GoodRx Goodall, arial;
  --font-family-header: GoodRx Moon, arial;
  --font-family-display: GoodRx Bolton, arial;
  --font-family-body: GoodRx Bolton, arial;
  --font-family-sans: arial, sans-serif;
  --border-radius-s: 4px;
  --border-radius-m: 8px;
  --border-radius-l: 16px;
  --border-radius-full: 999px;
  --screen-xs: '360px';
  --screen-sm: '430px';
  --screen-md: '768px';
  --screen-lg: '1024px';
  --screen-xl: '1280px';
}
