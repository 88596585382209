@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Postcss-import uses the 'style' key on a package.json first. main theme exists on that key.  */
@import '@goodrx/config.tailwind';

@font-face {
  font-family: 'Matter';
  src: url('../fonts/matter/WEB/Matter-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../fonts/matter/WEB/Matter-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../fonts/matter/WEB/Matter-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../fonts/matter/WEB/Matter-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/medium.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/mediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../fonts/bolton/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/demi.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/demiItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: italic;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../fonts/moon/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GoodRx Goodall';
  src: url('../fonts/goodall/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}

@layer base {
  body {
    @apply antialiased;
  }

  /* This is global css from CWF, it should be replaced with better solution in the future */
  * {
    border: 0 solid #e2e8f0;
  }
}

/* Hides Osano windows */
div.osano-cm-window > div.osano-cm-info-dialog-wrapper > div > ul > li:nth-child(5) {
  display: none;
}

.osano-cm-dialog {
  display: none;
  font-size: 72px;
}

.osano-cm-widget {
  display: none;
}

/* Hides pixel for Adstra below footer*/
img[alt="void"]{
  height: 0;
}
